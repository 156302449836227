import React, { Component } from 'react';
import '../styles/greenrWebsite/bootstrap.css';
import '../styles/greenrWebsite/website.scss';
import '../styles/greenrWebsite/responsive.css';
import Advisor from '../images/Advisor.svg';
import Institutions from '../images/Institutions.svg';
import How_we_catalyze from '../images/How_we_catalyze.svg';
import Fintech from '../images/Fintech.svg';
import predictablefinancial_img1 from '../images/predictablefinancial_img1.svg';
import Customer_360 from '../images/Customer_360.svg';
import what_we_do_line from '../images/what_we_do_line.svg';
import Solution from '../images/Solution.svg';
import Customer_relationship_building from '../images/Customer_relationship_building.svg';
import Greenr_Logo from '../images/Greenr_Logo.svg';
import Picture1 from '../images/Picture1.png';
import { connect } from 'react-redux';
import ScrollToTop from 'react-scroll-to-top';
import SignUpPopup from './SignUpPopup';
import ContactUsPopup from './ContactUsPopup';
import UserInfoPopup from './shared/UserInfoPopup';
import { signupFormFields, contactUsFormFields } from '../constants/dashboard';
import {
  transformTaxStateOptions,
  transformAllMenuItems,
} from '../redux/transformers/apiToClientResponseTransformer';
import {
  getAllTaxSlabsSuccess,
  getAllMenuItemsSuccess,
} from '../redux/actions/clientsInfoAction';
import {
  getAllPlannerMenuItems,
  getAllTaxSlabs,
} from '../services/dashboardSvc';
import { allTaxSlabsSelector } from '../selectors/plannerDetailsSelector';
import { loginFormFields, whoAreWe } from '../constants/dashboard';
import {
  getUserDetailsAction,
  setPrivacyAcceptanceValue,
  setPreviousPathValue,
  setTermsAcceptanceValue,
} from '../redux/actions/userAction';
import isEqual from 'lodash/isEqual';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { setSelectedClientIdAction } from '../redux/actions/clientsInfoAction';
import { getPlansByClientIdAction } from '../redux/actions/plansAction';
import history from '../history';
import { selectedClientIdSelector } from '../selectors/clientsInfoSelector';
import {
  getIsPrivacyCheckedSelector,
  getPrivacyAcceptanceValueSelector,
  getPreviousPathValueSelector,
  getTermsAcceptanceValueSelector,
  getIsTermsCheckedSelector,
} from '../selectors/loginUserSelector';
import { selectedPlanIdForClientIdSelector } from '../selectors/plannerDetailsSelector';
import { allPlannerMenuItemsSelector } from '../selectors/plannerDetailsSelector';
import {
  validatePhoneNumber,
  validateEmailId,
  validateNameField,
  validateInputFieId,
  formatPhoneNumber,
} from '../utils/validators';
import Controls from './controls/Controls';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
// var bcrypt = require('bcryptjs');
// var salt = bcrypt.genSaltSync(10);

const { loginUserName, loginPassword } = loginFormFields;

class Website extends Component {
  whatWeDo = React.createRef();
  whoWeServe = React.createRef();
  whatWeCover = React.createRef();
  howWeCatalyze = React.createRef();
  whoAreWe = React.createRef();

  state = {
    isCollapsed: true,
    dialogOpen: false,
    activeLink: '',
    isAPIFailed: false,
    userType: 'advisor',
    signupFields: [...signupFormFields],
    contactUsDialogOpen: false,
    ...this.getInitialSignupFormFields(),
    ...this.getInitialContactUsFormFields(),
    isUnAuthorizedAccess: false,
    isLoginFailed: false,
    userInfo: {},
    openUserInfoModal: false,
    type: 'password',
    ...this.getInitialLoginFormFields(),
  };

  scrollDown = (ref, from) => {
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: 'smooth',
    });
    this.setState({ activeLink: from });
  };

  getTaxSlabs = async () => {
    const { triggerGetAllTaxSlabsSuccess } = this.props;
    const { status, data: allTaxSlabs } = await getAllTaxSlabs();
    if (status === 200 && allTaxSlabs.length > 0) {
      triggerGetAllTaxSlabsSuccess(allTaxSlabs);
    }
  };

  getMenuItems = async () => {
    const { triggerGetAllMenuItemsSuccess } = this.props;
    const { status, data: allMenuItems } = await getAllPlannerMenuItems();
    if (status === 200 && allMenuItems.length > 0) {
      triggerGetAllMenuItemsSuccess(transformAllMenuItems(allMenuItems));
    }
  };

  componentDidMount() {
    const { triggerSetPreviousPathValue } = this.props;
    this.getTaxSlabs();
    this.getMenuItems();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    this.unlisten = history.listen((location, action) => {
      if (action === 'PUSH') {
        // Update the previous pathname when a new location is pushed
        triggerSetPreviousPathValue(location.pathname);
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      loggedInUserDetails: prevLoggedInUserDetails,
      privacyAcceptanceValue: prevPrivacyAcceptanceValue,
      termsAcceptanceValue: prevTermsAcceptanceValue,
    } = prevProps;
    const {
      loggedInUserDetails,
      privacyAcceptanceValue,
      termsAcceptanceValue,
      location: { pathname },
      previousPathValue,
      triggerSetPreviousPathValue,
      isPrivacyChecked,
      isTermsChecked,
    } = this.props;
    if (!isEqual(loggedInUserDetails, prevLoggedInUserDetails)) {
      const { loginMsg, loginSuccess, changePassword } = loggedInUserDetails;
      if (!loginSuccess) {
        this.setState({
          isLoginFailed: true,
          loginMessage: loginMsg,
          isLoading: false,
        });
        if (changePassword) {
          this.gotoForgotPassword();
        }
      } else {
        this.setState({
          isLoginFailed: false,
          loginMessage: loginMsg,
          isLoading: false,
        });
      }
    }
    if (
      pathname !== previousPathValue &&
      (previousPathValue === '/privacypolicy' ||
        previousPathValue === '/termsofuse') &&
      privacyAcceptanceValue &&
      termsAcceptanceValue &&
      isTermsChecked &&
      isPrivacyChecked
    ) {
      triggerSetPreviousPathValue(pathname);
      this.resetForm('signup');
      this.setDialogState(true);
    }
  }

  componentWillUnmount() {
    this.unlisten();
  }

  getInitialLoginFormFields() {
    return Object.keys(loginFormFields).reduce((acc, curr) => {
      const fieldValidKey = `is${curr}Valid`;
      return {
        ...acc,
        [curr]: '',
        [fieldValidKey]: true,
      };
    }, {});
  }

  loginCallbackFn = (loggedInUserDetails = {}) => {
    const { triggerSetSelectedClientIdAction, triggerGetPlansByClientId } =
      this.props;
    this.setLoaderState(false);
    if (loggedInUserDetails && Object.keys(loggedInUserDetails).length > 0) {
      const { userType, emailId, privacyAccepted, termsAccepted } =
        loggedInUserDetails;
      // initial landing page rules
      let page = { pathname: '/home' };
      if (!privacyAccepted) {
        page = {
          pathname: '/privacypolicy',
          state: {
            userType: userType,
            afterLogin: true,
            emailId: emailId,
            termsAccepted: termsAccepted,
          },
        };
      } else if (!termsAccepted) {
        page = {
          pathname: '/termsofuse',
          state: {
            userType: userType,
            afterLogin: true,
            emailId: emailId,
            privacyAccepted: privacyAccepted,
          },
        };
      } else {
        if (userType.toLowerCase() === 'direct') {
          page = {
            pathname: '/home',
            state: { isDirectUser: true },
          };
        } else if (userType.toLowerCase() === 'client') {
          triggerSetSelectedClientIdAction(emailId);
          triggerGetPlansByClientId({ clientId: emailId });
          page = {
            pathname: '/home',
            state: { isClientUser: true },
          };
        }
      }
      history.push(page);
    }
  };

  // getHashedPassword = (password) => {
  //   var salt = bcrypt.genSaltSync(10);
  //   const hashedPassword = bcrypt.hashSync(password, salt);
  //   return hashedPassword;
  // };

  // handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const { triggerLogin } = this.props;
  //   const { [loginUserName]: userName, [loginPassword]: password } = this.state;
  //   const payload = {
  //     username: userName?.toLowerCase(),
  //     password: password, //this.getHashedPassword(password),
  //     callback: this.loginCallbackFn,
  //   };
  //   this.setLoaderState(true);
  //   triggerLogin(payload);
  // };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { triggerLogin } = this.props;
    const { [loginUserName]: userName, [loginPassword]: password } = this.state;
    const payload = {
      emailId: userName?.toLowerCase(),
      password: password,
      callback: this.loginCallbackFn,
    };
    this.setLoaderState(true);
    triggerLogin(payload);
  };

  validateFormFields = (name, value) => {
    let isFieldValid = true;
    switch (name) {
      case 'loginUserName':
        isFieldValid = /$^|.+@.+..+/.test(value);
        break;
      case 'loginPassword':
      case 'password':
      case 'confirmPassword':
        isFieldValid = value.length > 5;
        break;
      case 'mobileNumber':
        isFieldValid = validatePhoneNumber(value);
        break;
      case 'emailId':
        isFieldValid = validateEmailId(value);
        break;
      default:
        isFieldValid = true;
    }
    this.setState({
      [`is${name}Valid`]: isFieldValid,
    });
  };

  setIsAPIFailed = (isAPIFailed) => {
    this.setState({
      isAPIFailed,
    });
  };

  resetForm = (formName) => {
    if (formName === 'login') {
      this.setState({
        loginUserName: '',
        loginPassword: '',
        loginErrors: '',
        isUnAuthorizedAccess: false,
        isLoginFailed: false,
      });
    }
  };

  gotoForgotPassword = () => {
    const { loggedInUserDetails } = this.props;
    const { loginMsg } = loggedInUserDetails;
    history.push({
      pathname: '/forgotPassword',
      state: { isForceChangePassword: true, loginMsg: loginMsg },
    });
  };

  navigateToDashboard = () => {
    history.push('/');
  };

  gotoTerms = () => {
    history.push('/termsofuse');
  };

  gotoPrivacy = () => {
    history.push('/privacypolicy');
  };

  getInitialSignupFormFields() {
    const formFields = signupFormFields.filter((el) => Object.keys(el).length);
    return formFields.reduce((acc, curr) => {
      const fieldValidKey = `is${curr.key}Valid`;
      return { ...acc, [curr.key]: '', [fieldValidKey]: true };
    }, {});
  }

  getInitialContactUsFormFields() {
    const formFields = contactUsFormFields.filter(
      (el) => Object.keys(el).length
    );
    return formFields.reduce((acc, curr) => {
      const fieldValidKey = `is${curr.key}Valid`;
      return { ...acc, [curr.key]: '', [fieldValidKey]: true };
    }, {});
  }

  handleLogin = () => {
    // sessionStorage.setItem('isLoginClicked', 'yes');
    history.push({ pathname: '/login' });
  };

  setContactUsDialogState = (isOpen) => {
    this.setState({
      contactUsDialogOpen: isOpen,
    });
  };
  setDialogState = (isOpen) => {
    this.setState({
      dialogOpen: isOpen,
    });
  };

  setLoaderState = (isLoading) => {
    this.setState({
      isLoading,
    });
  };

  setIsAPIFailed = (isAPIFailed) => {
    this.setState({
      isAPIFailed,
    });
  };

  validateForm(formName) {
    if (formName === 'login') {
      const {
        [`is${loginUserName}Valid`]: isUserNameValid,
        [`is${loginPassword}Valid`]: isPasswordValid,
        [loginUserName]: userName,
        [loginPassword]: password,
      } = this.state;
      return (
        isUserNameValid &&
        isPasswordValid &&
        userName.length > 0 &&
        password.length > 0
      );
    } else if (formName === 'signup') {
      let allFields = [...signupFormFields];
      const { userType } = this.state;
      let isAllFieldsValid = [];
      if (userType === 'direct') {
        allFields = signupFormFields.filter(
          (field) => ['company'].indexOf(field.key) === -1
        );
        isAllFieldsValid = allFields.map((field) => {
          const { [field.key]: value, [`is${field.key}Valid`]: isFieldValid } =
            this.state;
          return field.isRequired
            ? value.length > 0 && isFieldValid
            : isFieldValid;
        });
      } else {
        allFields = signupFormFields.filter(
          (field) => ['accessLevel'].indexOf(field.key) === -1
        );
        isAllFieldsValid = allFields.map((field) => {
          const { [field.key]: value, [`is${field.key}Valid`]: isFieldValid } =
            this.state;
          return field.isRequired
            ? value.length > 0 && isFieldValid
            : isFieldValid;
        });
      }
      return isAllFieldsValid.indexOf(false) === -1;
    } else if (formName === 'contactus') {
      let isAllFieldsValid = [];
      isAllFieldsValid = contactUsFormFields.map((field) => {
        const { [field.key]: value, [`is${field.key}Valid`]: isFieldValid } =
          this.state;
        return field.isRequired
          ? value.length > 0 && isFieldValid
          : isFieldValid;
      });
      return isAllFieldsValid.indexOf(false) === -1;
    }
    return true;
  }

  validateField = (name, value) => {
    let isValid = true;
    switch (name) {
      case 'loginUserName':
        isValid = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(value);
        break;
      case 'loginPassword':
      case 'password':
      case 'confirmPassword':
        isValid = value.length >= 8;
        break;
      case 'mobileNumber':
        isValid = validatePhoneNumber(value);
        break;
      case 'emailId':
        isValid = validateEmailId(value);
        break;
      case 'firstName':
      case 'lastName':
        isValid = validateNameField(value);
        break;
      case 'company':
        isValid = validateInputFieId(value);
        break;
      default:
        isValid = true;
        break;
    }
    // Update the corresponding isValid state
    this.setState({
      [name]: isValid ? value.replace(/\s+/g, ' ') : value,
      [`is${name}Valid`]: isValid,
    });
  };

  onInputChange = (e, name) => {
    const { value = '' } = e.target;
    let newValue = value;
    if (name === 'mobileNumber') {
      newValue = formatPhoneNumber(value);
    }
    const { signupFields } = this.state;
    let allFields = [...signupFields];
    if (name === 'userType') {
      allFields = [...signupFormFields];
      if (value === 'direct') {
        allFields = allFields.filter(
          (field) => ['company'].indexOf(field.key) === -1
        );
      } else {
        allFields = allFields.filter(
          (field) => ['accessLevel'].indexOf(field.key) === -1
        );
      }
    }
    this.setState(
      {
        isUnAuthorizedAccess: false,
        isLoginFailed: false,
        loginMessage: '',
        signupFields: allFields,
      },
      () => {
        this.validateField(name, newValue);
      }
    );
  };

  resetForm = (formName) => {
    if (formName === 'signup') {
      this.setState((prevState) => {
        return {
          ...prevState,
          ...this.getInitialSignupFormFields(),
        };
      });
    } else {
      this.setState((prevState) => {
        return {
          ...prevState,
          ...this.getInitialContactUsFormFields(),
        };
      });
    }
  };

  navigateToResetPassword = () => {
    history.push({ pathname: '/forgotPassword' });
  };

  getMoreUserInfo = (userInfo) => {
    this.setState({ openUserInfoModal: true, userInfo });
  };

  setUserInfoModalState = () => {
    this.setState({ openUserInfoModal: false });
  };

  handleRegister = () => {
    const { triggerSetPrivacyAcceptanceValue, triggerSetTermsAcceptanceValue } =
      this.props;
    triggerSetPrivacyAcceptanceValue(false);
    triggerSetTermsAcceptanceValue(false);
    history.push({ pathname: '/privacypolicy', state: 'register' });
  };

  handleToggle = () => {
    const { type } = this.state;
    if (type === 'password') {
      this.setState({ type: 'text' });
    } else {
      this.setState({ type: 'password' });
    }
  };

  // nav_bar_toggle
  toggleCollapse = () => {
    this.setState((prevState) => ({
      isCollapsed: !prevState.isCollapsed,
    }));
  };

  closeCollapse = () => {
    this.setState({
      isCollapsed: true,
    });
  };

  render() {
    const { isCollapsed } = this.state;
    const {
      alltaxSlabs,
      allMenuItems,
      privacyAcceptanceValue,
      termsAcceptanceValue,
      loggedInUserDetails,
    } = this.props;
    // const { failedLoginAttempts = 0 } = loggedInUserDetails || {};
    const { accessLevelMenuItems = [] } = allMenuItems || {};
    const {
      [loginUserName]: userName,
      [loginPassword]: password,
      isLoading,
      isUnAuthorizedAccess,
      [`is${loginUserName}Valid`]: isUserNameValid,
      [`is${loginPassword}Valid`]: isPasswordValid,
      dialogOpen,
      contactUsDialogOpen,
      signupFields,
      isAPIFailed,
      isLoginFailed,
      activeLink,
      openUserInfoModal,
      userInfo,
      loginMessage,
      type,
    } = this.state;
    return (
      <>
        <SignUpPopup
          dialogOpen={dialogOpen}
          dialogClass="signup-contact-dialog-container"
          setFormFieldValue={this.onInputChange}
          setDialogState={this.setDialogState}
          formValues={{ ...this.state }}
          isConfirmButtonDisabled={!this.validateForm('signup')}
          setIsAPIFailed={this.setIsAPIFailed}
          taxStateOptions={transformTaxStateOptions(alltaxSlabs)}
          signupFields={signupFields}
          accessLevels={accessLevelMenuItems}
          privacyAcceptanceValue={privacyAcceptanceValue}
          termsAcceptanceValue={termsAcceptanceValue}
        />
        <ContactUsPopup
          dialogClass="signup-contact-dialog-container"
          dialogOpen={contactUsDialogOpen}
          setFormFieldValue={this.onInputChange}
          setDialogState={this.setContactUsDialogState}
          formValues={{ ...this.state }}
          isConfirmButtonDisabled={!this.validateForm('contactus')}
          setIsAPIFailed={this.setIsAPIFailed}
        />
        <header>
          <nav className="navbar navbar-expand-lg navbar-light greener_website_nav">
            <div className="container">
              <span className="navbar-brand">
                <img src={Greenr_Logo} alt="" title="" />
              </span>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={this.toggleCollapse}
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className={`collapse navbar-collapse ${
                  isCollapsed ? '' : 'show'
                }`}
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav flex-column d-xl-none d-lg-none">
                  <li className="nav-item ">
                    <span
                      className="nav-link"
                      onClick={(e) => {
                        this.scrollDown(this.whatWeDo, 'whatWeDo');
                        this.closeCollapse();
                      }}
                    >
                      What we do
                    </span>
                  </li>
                  <li className="nav-item ">
                    <span
                      className="nav-link"
                      onClick={(e) => {
                        this.scrollDown(this.whoWeServe, 'whoWeServe');
                        this.closeCollapse();
                      }}
                    >
                      Who we serve
                    </span>
                  </li>

                  <li className="nav-item ">
                    <span
                      className="nav-link"
                      onClick={(e) => {
                        this.scrollDown(this.whatWeCover, 'whatWeCover');
                        this.closeCollapse();
                      }}
                    >
                      what we cover
                    </span>
                  </li>

                  <li className="nav-item ">
                    <span
                      className="nav-link"
                      onClick={(e) => {
                        this.scrollDown(this.howWeCatalyze, 'howWeCatalyze');
                        this.closeCollapse();
                      }}
                    >
                      How we catalyze
                    </span>
                  </li>

                  <li className="nav-item ">
                    <span
                      className="nav-link"
                      onClick={(e) => {
                        this.scrollDown(this.whoAreWe, 'whoAreWe');
                        this.closeCollapse();
                      }}
                    >
                      Who we are
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>
        <main>
          <section className="Grenner_Financial_Roots_main_outer">
            <div className="container">
              <div className="row">
                <div className="col-xl-7 col-lg-6">
                  <div className="images">
                    <img src={Picture1} title="" alt="" />
                  </div>
                </div>

                <div className="col-xl-5 col-lg-6">
                  <div className="banner_main_title">
                    <div className="main_title">
                      <h3 className="text-white">Building</h3>
                      <h2 className="text-white">Financial Roots</h2>
                    </div>
                  </div>
                  <div className="tab-container-one">
                    <form onSubmit={this.handleSubmit}>
                      <div className="row">
                        <div className="col-lg-6 mb-3">
                          <Controls.Input
                            type="email"
                            name="userName"
                            label="User Name"
                            className="text-white"
                            // aria-describedby="emailHelp"
                            placeholder=" User Name"
                            value={userName}
                            onChange={(e) =>
                              this.onInputChange(e, loginUserName)
                            }
                            errorText={
                              userName
                                ? 'Invalid Email'
                                : 'This is a mandatory field'
                            }
                            required
                            isValid={isUserNameValid}
                          />
                        </div>
                        <div
                          className="col-lg-6 mb-3"
                          style={{ position: 'relative' }}
                        >
                          <Controls.Input
                            type={type}
                            name="password"
                            label="Password"
                            className="text-white"
                            placeholder=" Password (min 8 characters)"
                            value={password}
                            onChange={(e) =>
                              this.onInputChange(e, loginPassword)
                            }
                            errorText={
                              password
                                ? 'Invalid Password'
                                : 'This is a mandatory field'
                            }
                            required
                            minLength="8"
                            isValid={isPasswordValid}
                          />
                          {password && (
                            <span
                              style={{
                                position: 'absolute',
                                top: '68%',
                                transform: 'translateY(-50%)',
                                right: '20px',
                              }}
                              onClick={this.handleToggle}
                            >
                              {type === 'password' ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="button_group">
                        <button
                          type="submit"
                          className={
                            this.validateForm('login')
                              ? 'common_button'
                              : 'common_secondary_button'
                          }
                          disabled={!this.validateForm('login')}
                        >
                          Login
                        </button>
                      </div>
                    </form>
                    <div className="Grenner_login_bottom_button">
                      <ul>
                        <li style={{ color: '#fff' }}>
                          By Continuing you agree to our{' '}
                          <button
                            type="button"
                            className={'button-like-link'}
                            onClick={this.gotoTerms}
                          >
                            Terms
                          </button>
                          {/* <a
                            href="/termsofuse"
                            target="_self"
                            rel="noopener noreferrer"
                            style={{ fontSize: '14px' }}
                          >
                            Terms
                          </a>{' '} */}{' '}
                          and{' '}
                          <button
                            className={'button-like-link'}
                            type="button"
                            onClick={this.gotoPrivacy}
                          >
                            Privacy
                          </button>
                          {/* <a
                            href="/privacypolicy"
                            target="_self"
                            rel="noopener noreferrer"
                            style={{ fontSize: '14px' }}
                          >
                            Privacy
                          </a> */}
                        </li>
                      </ul>
                    </div>
                    <div className="Grenner_login_bottom_button">
                      <ul>
                        <li>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={this.navigateToResetPassword}
                          >
                            Forgot Password?
                          </button>
                        </li>
                        <li>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                              this.handleRegister();
                            }}
                          >
                            Register Now
                          </button>
                        </li>
                        <li>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                              this.resetForm('contactUs');
                              this.setContactUsDialogState(true);
                            }}
                          >
                            Contact Us
                          </button>
                        </li>
                      </ul>
                    </div>
                    {isUnAuthorizedAccess && (
                      <Stack
                        sx={{
                          width: '100%',
                          padding: '5px',
                          margin: '10px',
                        }}
                        spacing={2}
                      >
                        <Alert severity="error">
                          Unauthorized access, please contact administrator!
                        </Alert>
                      </Stack>
                    )}
                    {loginMessage && isLoginFailed && (
                      <Stack
                        sx={{
                          width: '100%',
                          padding: '5px',
                          margin: '10px',
                        }}
                        spacing={2}
                      >
                        <Alert severity="error">
                          {loginMessage}
                          {/* ,{' '}
                          {failedLoginAttempts <= 3
                            ? `retries left ${3 - failedLoginAttempts}.`
                            : 0} */}
                        </Alert>
                      </Stack>
                    )}
                    {loginMessage && !isLoginFailed && !isLoading && (
                      <Stack
                        sx={{
                          width: '100%',
                          padding: '5px',
                          margin: '10px',
                        }}
                        spacing={2}
                      >
                        <Alert severity="success">{loginMessage}</Alert>
                      </Stack>
                    )}
                  </div>

                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <button
                        className={
                          activeLink === 'whatWeDo'
                            ? 'nav-link active'
                            : 'nav-link'
                        }
                        onClick={() =>
                          this.scrollDown(this.whatWeDo, 'whatWeDo')
                        }
                      >
                        What we do
                      </button>
                    </li>
                    <li className="nav-item ">
                      <button
                        className={
                          activeLink === 'whoAreWe'
                            ? 'nav-link active'
                            : 'nav-link'
                        }
                        onClick={() =>
                          this.scrollDown(this.whoAreWe, 'whoAreWe')
                        }
                      >
                        Who we are
                      </button>
                    </li>
                    <li className="nav-item ">
                      <button
                        className={
                          activeLink === 'whatWeCover'
                            ? 'nav-link active'
                            : 'nav-link'
                        }
                        onClick={() =>
                          this.scrollDown(this.whatWeCover, 'whatWeCover')
                        }
                      >
                        what we cover
                      </button>
                    </li>
                    <li className="nav-item ">
                      <button
                        className={
                          activeLink === 'whoWeServe'
                            ? 'nav-link active'
                            : 'nav-link'
                        }
                        onClick={() =>
                          this.scrollDown(this.whoWeServe, 'whoWeServe')
                        }
                      >
                        Who we serve
                      </button>
                    </li>
                    <li className="nav-item ">
                      <button
                        className={
                          activeLink === 'howWeCatalyze'
                            ? 'nav-link active'
                            : 'nav-link'
                        }
                        onClick={() =>
                          this.scrollDown(this.howWeCatalyze, 'howWeCatalyze')
                        }
                      >
                        How we catalyze
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <ScrollToTop />
          <section
            className="What_we_do_main_outer"
            id="whatwedo"
            ref={this.whatWeDo}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-5 col-md-12">
                  <div className="main_title">
                    <p>What we do</p>
                    <h3>We assist you in building</h3>
                    <h2>Stronger Relationships</h2>
                    <h3>With your clients</h3>
                    <p className="small_text">
                      The Personal Touch - Green’r is a platform that assists
                      you build stronger relationships by enabling you help your
                      clients build their financial roots through a
                      comprehensive, resilient and personalized financial plan.
                    </p>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 What_we_do_content_column">
                  <div className="what_we_do_line_outer">
                    <h5>Journey enabled by Green'r</h5>
                    <img src={what_we_do_line} alt="" title="" />
                  </div>
                  <div className="What_we_do_content">
                    <div className="What_we_do_content_box box_1">
                      <img src={Customer_360} alt="" title="" />
                      <p>Customer 360°</p>
                    </div>

                    <div className="What_we_do_content_box box_2">
                      <img src={Solution} alt="" title="" />
                      <p>
                        What-if scenarios & <br />
                        optimized Solutions
                      </p>
                    </div>

                    <div className="What_we_do_content_box box_3">
                      <img
                        src={Customer_relationship_building}
                        alt=""
                        title=""
                      />
                      <p>
                        Customer <br />
                        relationship building
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="greenr_Who_we_are_outer" ref={this.whoAreWe}>
            <div className="container">
              <div className="row">
                <div className="col-lg-3">
                  <div className="main_title">
                    <p>Who we are</p>
                    <h3>Meet the Team</h3>

                    <p className="small_text">
                      The Green'r Financial Team brings a combined 60+ years of
                      industry expertise across FinTech, wealth management,
                      capital markets and operations management.
                    </p>
                    <p className="small_text">
                      The team has led successful startups and have held senior
                      executive positions within large multinationals.
                    </p>
                    <p className="small_text">
                      The team is passionate about client financial wellness and
                      assisting advisors in building strong relationships with
                      their clients.
                    </p>
                  </div>
                </div>

                <div className="col-lg-9 col-md-12">
                  <div className="greenr_who_we_are_content">
                    {whoAreWe.map((w) => (
                      <div
                        className="greenr_who_we_are_content_box"
                        onClick={() => this.getMoreUserInfo(w)}
                      >
                        <div className="greenr_who_we_are_content_box_img_outer">
                          <img src={w.images[0].name} alt="" title="" />
                        </div>
                        <div className="greenr_who_we_are_content_inner">
                          <h6>
                            {w.name}, {w.degree}
                          </h6>
                          <h5>{w.designation}</h5>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="greenr_What_we_cover"
            id="whatwecover"
            ref={this.whatWeCover}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-12">
                  <div className="main_title">
                    <p>What we cover</p>
                    <h3>A more resilient &</h3>
                    <h2>predictable financial</h2>
                    <h3>Future for clients</h3>
                    <p className="small_text">
                      Our approach allows you to view your customer’s 360°
                      financial plan and scale your ability to quickly service
                      customer needs.
                    </p>
                  </div>
                </div>
                <div className="col-lg-8 col-md-12">
                  <div className="predictablefinancial_content">
                    <div className="predictablefinancial_content_image_div">
                      <img src={predictablefinancial_img1} alt="" title="" />
                    </div>
                    {/* <div className="text text_1">Income, Expenses, and Taxes <br />401k, IRA, Brokerage <br/>RSU, Pension, Deferred Comp</div>
                            <div className="text text_2">Vacations & Vehicles Wedding <br />Kids & 529 plans</div>
                            <div className="text text_3">Credit Cards Student <br />Loans Other Loans</div>
                            <div className="text text_4">Retirement Accounts <br />Brokerage & Cash <br />Accounts Emergency <br />Funds</div>
                            <div className="text text_5">Term life Whole life <br />Liability coverage</div>
                            <div className="text text_6">Primary & second <br />homes Investment <br />properties</div>
                            <div className="text text_7">When & Where to <br />retire Decumulation <br />strategy Dividends & <br />Liquidity planning <br />Expense management</div>
                            <div className="text text_8">Maximize estate <br />transfer</div> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="Greenr_Who_we_serve"
            id="whoweserve"
            ref={this.whoWeServe}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="main_title">
                    <p>Who we serve</p>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <div className="Greenr_Who_we_serve_content">
                    <h5>Advisor</h5>
                    <div className="Who_we_serve_content_img">
                      <img
                        src={Customer_relationship_building}
                        alt=""
                        title=""
                      />
                    </div>
                    <p>
                      Discuss integrated solutions with customer in real-time
                      through charts/reports in the context of their overall
                      plan and help them establish a resilient future.
                    </p>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="Greenr_Who_we_serve_content">
                    <h5>Institutions</h5>
                    <div className="Who_we_serve_content_img">
                      <img src={Customer_360} alt="" title="" />
                    </div>
                    <p>
                      Catalyze your business growth by empowering your team with
                      Green’r Digital Capabilities, integrated within your
                      ecosystem, and offering personalized solutions to your
                      customers.
                    </p>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="Greenr_Who_we_serve_content">
                    <h5>Fintech</h5>
                    <div className="Who_we_serve_content_img">
                      <img src={Solution} alt="" title="" />
                    </div>
                    <p>
                      Help your customers understand financial intricacies,
                      identify gaps in their personal plan, and solve through
                      solutions that you offer.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="Greenr_How_we_catalyze"
            id="howwecatalyze"
            ref={this.howWeCatalyze}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="main_title">
                    <p>How we catalyze</p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="How_we_catalyze_img_outer">
                    <img src={How_we_catalyze} alt="" title="" />
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="How_we_catalyze_content">
                    <h5>Resilient Growth for Client</h5>
                    {/* <div className="Who_we_serve_content_img">
                      <img src={Advisor} alt="" title="" />
                    </div> */}
                    <ul>
                      <li>Expense Management</li>
                      <li>Account Aggregation</li>
                      <li>Lifetime Projections</li>
                      <li>Gap Identification</li>
                      <li>Call-To-Action</li>
                      <li>Robust Solutions</li>
                      <li>Scenario Analysis</li>
                      <li>Goal Optimization</li>
                    </ul>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="How_we_catalyze_content">
                    <h5>Business Growth for Advisor</h5>
                    {/* <div className="Who_we_serve_content_img">
                      <img src="../images/Advisor.svg" alt="" title="" />
                    </div> */}
                    <ul>
                      <li>Book of Business Overview</li>
                      <li>Alerts & Tasks</li>
                      <li>Client Collaboration</li>
                      <li>Flexible Reporting</li>
                      <li>Lead Generation</li>
                      <li>Demo Accounts</li>
                      <li>Competitive Pricing</li>
                      <li>Mobile/Tablet Access</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        {openUserInfoModal && (
          <UserInfoPopup
            dialogOpen={openUserInfoModal}
            userInfo={userInfo}
            setDialogState={this.setUserInfoModalState}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    alltaxSlabs: allTaxSlabsSelector(state),
    loggedInUserDetails:
      (state.loggedInUser && state.loggedInUser.userDetails) || {},
    clients: state.clientInfoReducer.clients || [],
    plans: state.plansReducer.plans || [],
    selectedClientId: selectedClientIdSelector(state),
    selectedPlanId: selectedPlanIdForClientIdSelector(state),
    allMenuItems: allPlannerMenuItemsSelector(state),
    privacyAcceptanceValue: getPrivacyAcceptanceValueSelector(state),
    previousPathValue: getPreviousPathValueSelector(state),
    isPrivacyChecked: getIsPrivacyCheckedSelector(state),
    termsAcceptanceValue: getTermsAcceptanceValueSelector(state),
    isTermsChecked: getIsTermsCheckedSelector(state),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    triggerGetAllTaxSlabsSuccess: (payload) =>
      dispatch(getAllTaxSlabsSuccess(payload)),
    triggerGetAllMenuItemsSuccess: (payload) =>
      dispatch(getAllMenuItemsSuccess(payload)),
    triggerLogin: (payload) => dispatch(getUserDetailsAction(payload)),
    triggerGetPlansByClientId: (payload) =>
      dispatch(getPlansByClientIdAction(payload)),
    triggerSetSelectedClientIdAction: (payload) =>
      dispatch(setSelectedClientIdAction(payload)),
    triggerSetPrivacyAcceptanceValue: (payload) =>
      dispatch(setPrivacyAcceptanceValue(payload)),
    triggerSetTermsAcceptanceValue: (payload) =>
      dispatch(setTermsAcceptanceValue(payload)),
    triggerSetPreviousPathValue: (payload) =>
      dispatch(setPreviousPathValue(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Website);
