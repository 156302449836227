import { GET, POST, CUSTOMGET, CUSTOMPOST } from '../api/fetch_wrapper';
import { convertObjToQueryStr } from '../utils/apirequestutil';
import { API_HOST_URL } from './apiHost';

export async function getCompanyAssetsUnderManagement(companyName) {
  return GET(
    `${API_HOST_URL()}/selectAdvisorAumByCompanyName?companyName=${companyName}`
  );
}

export async function getInvAssetAllocationsByCompanyName(companyName) {
  return GET(
    `${API_HOST_URL()}/selectInvAssetAllocationsByCompanyName?companyName=${companyName}`
  );
}

export async function getAllAdvisorsByCompanyName(companyName) {
  return GET(
    `${API_HOST_URL()}/selectAdvisorsByCompanyName?companyName=${companyName}`
  );
}
