import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import add from '../../images/add.svg';
import bell from '../../images/bell.svg';
import Controls from '../controls/Controls';
import { tenures } from '../../constants/CompanyHome';
import { getAllAdvisorsByCompanyName } from '../../services/businessDashboardSvc';

const SecondCardComponent = (props) => {
  const {
    activeCard,
    setActiveCardState,
    taxStateVals,
    advisorSelectedState,
    triggerSetTenure,
    tenure,
    isClientsNotUpdatedLoading,
    finalClientsNotUpdated,
    selectedClientId,
    handleClientOnboardingModalOpen,
    setAdvisorSelectedState,
    companyName,
  } = props;

  const [allAdvisorList, setAllAdvisorList] = useState([]);

  const triggerGetAllAdvisorsByCompanyName = async () => {
    const { status, data: advisorsList } = await getAllAdvisorsByCompanyName(
      companyName
    );
    if (status === 200) {
      setAllAdvisorList(advisorsList);
    }
  };

  //   useEffect(() => {
  //     triggerGetAllAdvisorsByCompanyName();
  //   }, []);

  return (
    <div
      className={`card home_main_card_outer three mb-4 ${
        activeCard === 'clients' ? `active` : ``
      }`}
      onClick={() => setActiveCardState('clients')}
    >
      <div className="card-body client_contact_recency_inner">
        <h5 className="text-white">Advisor Review</h5>
        <div className="Under_Management d-md-flex">
          <div className="Under_Management_image">
            <img src={bell} alt="" title="" />
          </div>
          <div className="Select_state_outer">
            <h6>State</h6>
            <Controls.Select
              options={taxStateVals || []}
              className="js-example-basic-single"
              placeholder="Select State"
              value={advisorSelectedState}
              onChange={setAdvisorSelectedState}
            />
          </div>
          <div className="Under_Management_content d-flex">
            <div className="Under_Management_content_right">
              <p
                className={'active'}
                onClick={triggerGetAllAdvisorsByCompanyName}
              >
                All
              </p>
              <h4>
                <span className="value">{allAdvisorList.length}</span>
              </h4>
            </div>
          </div>
        </div>
        {isClientsNotUpdatedLoading ? (
          <div className="select_client_outer">
            <CircularProgress
              sx={{
                color: '#fff',
                position: 'absolute',
                left: '50%',
                top: '80%',
              }}
              size={20}
            />
          </div>
        ) : (
          <div className="select_client_outer">
            <form className="">
              <div className="select_client_outer_form_row">
                <div className="client client-review-dd">
                  <div className="Select_Client">
                    <Controls.Select
                      className="js-example-basic-single"
                      name="Select_Advisor"
                      id="Select_Client"
                      placeholder="Select Advisor"
                      value={selectedClientId}
                      onChange={(event) => this.onClientChange(event)}
                      options={finalClientsNotUpdated.map((c) => {
                        return {
                          value: c.clientId,
                          label: c.clientName,
                        };
                      })}
                    />
                  </div>
                </div>
                <div className="plan client-review-dd advisor_add_btn_outer">
                  <button
                    type="button"
                    className="common-button"
                    onClick={() => handleClientOnboardingModalOpen(true)}
                  >
                    <img src={add} alt="" title="" />
                    Advisor
                  </button>
                  {/* <button
              type="button"
              className={
                !selectedClientId
                  ? 'common-button secondary-button'
                  : 'common-button'
              }
              disabled={!selectedClientId}
              onClick={() =>
                this.handleAddPlanModalOpen(true)
              }
            >
              <img src={add} alt="" title="" />
              Plan
            </button> */}
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default SecondCardComponent;
