import {
  GET_COMPANY_ASSETS_UNDER_MANAGEMENT_SUCCESS,
  GET_COMPANY_ASSETS_UNDER_MANAGEMENT_ERROR,
  COMPANY_ASSETS_LOADING,
  GET_INV_ASSET_ALLOCATIONS_BY_COMPANY_NAME_ERROR,
  GET_INV_ASSET_ALLOCATIONS_BY_COMPANY_NAME_SUCCESS,
  SET_INV_ASSET_ALLOCATIONS_BY_COMPANY_NAME_LOADING_ACTION,
} from '../actions/businessInfoAction';

const initialState = {
  companyAssetsUnderManagement: {},
  isCompanyAssetsLoading: false,
  isCmpnyInvAssetAllocationsLoading: false,
  companyInvAssetAllocations: [],
};

export default function businessInfo(state = initialState, action) {
  switch (action.type) {
    case GET_COMPANY_ASSETS_UNDER_MANAGEMENT_SUCCESS:
      return {
        ...state,
        companyAssetsUnderManagement: action.payload,
      };
    case GET_COMPANY_ASSETS_UNDER_MANAGEMENT_ERROR:
      return {
        ...state,
        companyAssetsUnderManagement: {},
      };
    case COMPANY_ASSETS_LOADING:
      return {
        ...state,
        isCompanyAssetsLoading: action.payload,
      };
    case GET_INV_ASSET_ALLOCATIONS_BY_COMPANY_NAME_SUCCESS:
    case GET_INV_ASSET_ALLOCATIONS_BY_COMPANY_NAME_ERROR:
      return { ...state, companyInvAssetAllocations: action.payload };
    case SET_INV_ASSET_ALLOCATIONS_BY_COMPANY_NAME_LOADING_ACTION:
      return {
        ...state,
        isCmpnyInvAssetAllocationsLoading: action.payload,
      };
    default:
      return state;
  }
}
