import { put, call, all, takeLatest } from 'redux-saga/effects';
import {
  GET_COMPANY_ASSETS_UNDER_MANAGEMENT,
  getCompanyAssetsUnderManagementSuccessAction,
  setCompanyAssetsLoadingAction,
  getCompanyAssetsUnderManagementErrorAction,
  GET_INV_ASSET_ALLOCATIONS_BY_COMPANY_NAME,
  setInvAssetAllocationsByCmpnyNmLoadingAction,
  getInvAssetAllocationsByCmpnyNmErrorAction,
  getInvAssetAllocationsByCmpnyNmSuccessAction,
} from '../actions//businessInfoAction';
import {
  getCompanyAssetsUnderManagement,
  getInvAssetAllocationsByCompanyName,
} from '../../services/businessDashboardSvc';

function* triggerGetCompanyAssetsUnderManagement(request) {
  try {
    yield put(setCompanyAssetsLoadingAction(true));
    const { status, data: companyAssetsAum } = yield call(
      getCompanyAssetsUnderManagement,
      request.payload
    );
    if (status === 200) {
      const companyAssetsUnderManagement = {
        ...companyAssetsAum,
      };
      yield put(setCompanyAssetsLoadingAction(false));
      yield put(
        getCompanyAssetsUnderManagementSuccessAction(
          companyAssetsUnderManagement
        )
      );
    } else {
      yield put(setCompanyAssetsLoadingAction(false));
      yield put(getCompanyAssetsUnderManagementErrorAction({}));
    }
  } catch (err) {
    yield put(setCompanyAssetsLoadingAction(false));
    yield put(getCompanyAssetsUnderManagementErrorAction({}));
  }
}

function* triggerGetInvAssetAllocationsByCompanyName(action) {
  try {
    yield put(setInvAssetAllocationsByCmpnyNmLoadingAction(true));
    const { status, data } = yield call(
      getInvAssetAllocationsByCompanyName,
      action.payload
    );
    if (status === 200) {
      yield put(setInvAssetAllocationsByCmpnyNmLoadingAction(false));
      yield put(getInvAssetAllocationsByCmpnyNmSuccessAction(data));
    } else {
      yield put(setInvAssetAllocationsByCmpnyNmLoadingAction(false));
      yield put(getInvAssetAllocationsByCmpnyNmErrorAction({}));
    }
  } catch (err) {
    yield put(setInvAssetAllocationsByCmpnyNmLoadingAction(false));
    yield put(getInvAssetAllocationsByCmpnyNmErrorAction({}));
  }
}

export function* watchBusinessInfoByAdvisor() {
  yield takeLatest(
    GET_COMPANY_ASSETS_UNDER_MANAGEMENT,
    triggerGetCompanyAssetsUnderManagement
  );
  yield takeLatest(
    GET_INV_ASSET_ALLOCATIONS_BY_COMPANY_NAME,
    triggerGetInvAssetAllocationsByCompanyName
  );
}
